import React, { useState } from "react";
import { Segment, Form, Button, Icon, Input } from "semantic-ui-react";
import FilterModal from "./filterModal";

const Navigation = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [applyFilters, setApplyFilters] = useState(false);
  const [watchList, setWatchList] = useState(false);

  return (
    <React.Fragment>
      <Segment id="navigation">
        <div>
          <Button
            style={{ marginRight: "30px" }}
            className="playfair"
            onClick={() => {
              setWatchList(!watchList);
            }}
          >{`WatchList: ${watchList ? "On" : "Off"}`}</Button>
          <Button
            onClick={() => {
              setModalOpen(true);
            }}
            className={`playfair  ` + (applyFilters ? "filters-on" : "")}
          >
            {applyFilters ? "Filters On" : "Screener"}
          </Button>
          {applyFilters && (
            <Button
              className="clear playfair"
              onClick={() => {
                setApplyFilters(false);
              }}
            >
              Clear
            </Button>
          )}
        </div>
        <div>
          <Form>
            <Form.Field>
              <Input
                style={{ minWidth: "300px" }}
                type="text"
                icon="search"
                placeholder="Search &amp; Add (Symbol / Name)"
              />
              <Button>
                <Icon name="search" />
              </Button>
            </Form.Field>
          </Form>
          <Button className="playfair" style={{ marginLeft: "30px" }}>
            Dividend Scouts Rankings
          </Button>
          <Button style={{ marginLeft: "30px" }}>
            {" "}
            <Icon name="question" />
          </Button>
        </div>
      </Segment>
      <FilterModal
        open={modalOpen}
        setModalOpen={setModalOpen}
        setApplyFilters={setApplyFilters}
      />
    </React.Fragment>
  );
};

export default Navigation;
