import React from "react";
import { Segment } from "semantic-ui-react";

const ExpandedDetails = (props) => {
  const {
    name,
    industryName,
    type,
    epsFwd,
    epsSurprise,
    beta,
    frequency,
    declareDate,
    exDividend,
    paymentsDate,
    oneYr,
    threeYr,
    fiveYr,
    tenYr,
    oneYrCagr,
    threeYrCagr,
    fiveYrCagr,
    tenYrCagr,
  } = props;

  return (
    <div className="segment-wrapper">
      <Segment>
        <div className="col col-1">
          <label>Name</label>
          <p>{name}</p>
          <label>Industry Name</label>
          <p>{industryName}</p>
          <label>Type</label>
          <p>{type}</p>
        </div>
        <div className="col col-2">
          <label>EPS FWD</label>
          <p>{epsFwd}</p>
          <label>EPS Surprise</label>
          <p>{epsSurprise}</p>
          <label>Beta</label>
          <p>{beta}</p>
        </div>
        <div className="col col-3">
          <label>Dividend Dates</label>
          <p>
            Frequency<span>{frequency}</span>
          </p>
          <p>
            Declare Date<span>{declareDate}</span>
          </p>
          <p>
            Ex-Dividend<span>{exDividend}</span>
          </p>
          <p>
            Payments Date<span>{paymentsDate}</span>
          </p>
        </div>
        <div className="col col-4">
          <label>Price Performance</label>
          <p>
            01 Yr<span>{oneYr}</span>
          </p>
          <p>
            03 Yr<span>{threeYr}</span>
          </p>
          <p>
            05 Yr<span>{fiveYr}</span>
          </p>
          <p>
            10 Yr<span>{tenYr}</span>
          </p>
        </div>
        <div className="col col-5">
        <label>Dividend Growth Summary</label>
          <p>
            01 Yr<span>{oneYrCagr}</span>
          </p>
          <p>
            03 Yr<span>{threeYrCagr}</span>
          </p>
          <p>
            05 Yr<span>{fiveYrCagr}</span>
          </p>
          <p>
            10 Yr<span>{tenYrCagr}</span>
          </p>
        </div>
      </Segment>
    </div>
  );
};

export default ExpandedDetails;
