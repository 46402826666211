import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Icon,
  Label,
  Button,
  Header,
  Modal,
  Form,
  Dropdown,
} from "semantic-ui-react";
// import { setShowSearch, clearFullData } from "./dataSlice";
import RecordsTable from "./recordsTable";

const Search = () => {
  // Search Vals From Dropdowns
  const [processingStatus, setProcessingStatus] = useState(null);
  const [batchDate, setBatchDate] = useState(null);
  const [employmentHistoryStatus, setEmploymentHistoryStatus] = useState(null);
  const [location, setLocation] = useState(null);
  // Search Results
  const [searchResults, setSearchResults] = useState(null);
  const [searchResultsText, setSearchResultsText] = useState('')
  // Dropdowns Populated from Global State
  const searchOptions = useSelector((state) => state.data.searchOptions);
  // FullData to Search
  const fullData = useSelector((state) => state.data.fullData);
  const dispatch = useDispatch();

  const filterData = () => {
    setSearchResultsText('Searching...')

    const results = [];
    const filters = [
      processingStatus,
      batchDate,
      employmentHistoryStatus,
      location,
    ].filter((item) => {
      if (item !== null && item !== undefined) {
        return item;
      }
    });

    fullData.map((item) => {
      let pass = true;
      filters.map((filter) => {
        if (item[filter.key] !== filter.value) {
          pass = false;
        }
      });
      if (pass) {
        results.push(item);
      }
    });

    results.length === 0 ? setSearchResultsText('No Results') : setSearchResultsText('') 

    setSearchResults(results);
  };

  const clearFilters = () => {
    setSearchResultsText('')
    setProcessingStatus(null)
    setBatchDate(null)
    setEmploymentHistoryStatus(null)
    setLocation(null)
    setSearchResults(null)
  }

  return (
    <Modal
      id="searchModal"
      size={"fullscreen"}
      open={searchOptions}
      onClose={() => {
        // dispatch(setShowSearch(false));
      }}
    >
      <Header icon="search" content="Search All Records" />
      <Modal.Content>
        <Form>
          <Form.Field>
            <Label>Processing Status</Label>
            <Dropdown
              placeholder="2 Options"
              options={searchOptions.processingStatus}
              onChange={(e, values) => {
                setProcessingStatus({ key: "processed", value: values.value });
              }}
              value={processingStatus ? processingStatus.value : null}
            />
          </Form.Field>
          <Form.Field>
            <Label>Batch Date</Label>
            <Dropdown
              placeholder={`${searchOptions.batchDate.length} Options`}
              options={searchOptions.batchDate}
              onChange={(e, values) => {
                setBatchDate({ key: "batchdate", value: values.value });
              }}
              id="batchDateSearchDropdown"
              value={batchDate ? batchDate.value : null}
            />
          </Form.Field>
          <Form.Field>
            <Label>Employment History Status</Label>
            <Dropdown
              placeholder={`${searchOptions.employmentHistoryStatus.length} Options`}
              options={searchOptions.employmentHistoryStatus}
              onChange={(e, values) => {
                setEmploymentHistoryStatus({
                  key: "employmentHistoryStatus",
                  value: values.value,
                });
              }}
              value={employmentHistoryStatus ? employmentHistoryStatus.value : null}
            />
          </Form.Field>
          <Form.Field>
            <Label>Location</Label>
            <Dropdown
              placeholder={`${searchOptions.location.length} Options`}
              options={searchOptions.location}
              onChange={(e, value) => {
                setLocation({ key: "location", value: value.value });
              }}
              value={location ? location.value : null}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      {searchResults && searchResults.length > 0 ? (
        <div id="searchTableWrap">
          <RecordsTable props={searchResults} />
        </div>
      ) : (
      <h3 style={{ opacity: "0.5", textAlign: "center" }}>{searchResultsText}</h3>
      )}
      <Modal.Actions>
        <Button
          color="red"
          onClick={() => {
            // dispatch(setShowSearch(false));
          }}
        >
          <Icon name="remove" /> Close
        </Button>
        <Button
          color="blue"
          onClick={() => {
            clearFilters()
          }}
        >
          <Icon name="repeat" /> Reset
        </Button>
        <Button
          color="green"
          onClick={() => {
            filterData();
          }}
        >
          <Icon name="search" /> Search
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default Search;
