import React, { useState } from "react";
import { Button, Table } from "semantic-ui-react";
import ExpandedDetails from "./expandedDetails";

const TableRow = (props) => {
  const { d, setHoverCol } = props;
  const [showDetails, setShowDetails] = useState(false);
  const [selected, setSelected] = useState(false);
  const [hover, setHover] = useState(false);
  let dividendSafety;

  if (d.dividendSafety >= 80) {
    dividendSafety = "a";
  } else if (d.dividendSafety >= 60) {
    dividendSafety = "b";
  } else if (d.dividendSafety >= 40) {
    dividendSafety = "c";
  } else if (d.dividendSafety >= 20) {
    dividendSafety = "d";
  } else if (d.dividendSafety >= 0) {
    dividendSafety = "f";
  }

  return (
    <React.Fragment>
      <Table.Row
        className={selected ? "selected" : ""}
        key={d.id}
        onClick={() => {
          // dispatch(
          //   // setSelectedData(
          //   //   JSON.parse(JSON.stringify(props.props[index]))
          //   // )
          // );
        }}
      >
        <Table.Cell>
          <Button
            icon={`${selected ? (hover ? "cancel" : "check") : "plus"}`}
            onClick={(e) => {
              e.stopPropagation();
              setSelected(!selected);
            }}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
            className={selected ? "selected" : ""}
            style={{ cursor: "copy" }}
          />
        </Table.Cell>
        <Table.Cell
          onMouseEnter={() => {
            setHoverCol('hover-col-1');
          }}
          onMouseLeave={() => {
            setHoverCol(null);
          }}
        >
          {d.symbol ? d.symbol : "Error"}
        </Table.Cell>
        <Table.Cell
          onMouseEnter={() => {
            setHoverCol('hover-col-2');
          }}
          onMouseLeave={() => {
            setHoverCol(null);
          }}
        >
          {d.priceOpen ? d.priceOpen : "Error"}
        </Table.Cell>
        <Table.Cell
          onMouseEnter={() => {
            setHoverCol('hover-col-3');
          }}
          onMouseLeave={() => {
            setHoverCol(null);
          }}
        >
          {d.divFwd ? d.divFwd : "Error"}
        </Table.Cell>
        <Table.Cell
          onMouseEnter={() => {
            setHoverCol('hover-col-4');
          }}
          onMouseLeave={() => {
            setHoverCol(null);
          }}
        >
          {d.yieldFwd ? d.yieldFwd : "Error"}
        </Table.Cell>
        <Table.Cell
          onMouseEnter={() => {
            setHoverCol('hover-col-5');
          }}
          onMouseLeave={() => {
            setHoverCol(null);
          }}
        >
          {d.payoutRatio ? d.payoutRatio : "Error"}
        </Table.Cell>
        <Table.Cell
          onMouseEnter={() => {
            setHoverCol('hover-col-6');
          }}
          onMouseLeave={() => {
            setHoverCol(null);
          }}
        >
          {d.mktCap ? d.mktCap : "Error"}
        </Table.Cell>
        <Table.Cell
          onMouseEnter={() => {
            setHoverCol('hover-col-7');
          }}
          onMouseLeave={() => {
            setHoverCol(null);
          }}
        >
          {d.sector ? d.sector : "Error"}
        </Table.Cell>
        <Table.Cell
          onMouseEnter={() => {
            setHoverCol('hover-col-8');
          }}
          onMouseLeave={() => {
            setHoverCol(null);
          }}
        >
          {d.priceGrowth ? d.priceGrowth : "Error"}
        </Table.Cell>
        <Table.Cell
          onMouseEnter={() => {
            setHoverCol('hover-col-9');
          }}
          onMouseLeave={() => {
            setHoverCol(null);
          }}
        >
          {d.dividendGrowth ? d.dividendGrowth : "Error"}
        </Table.Cell>
        <Table.Cell
          onMouseEnter={() => {
            setHoverCol('hover-col-10');
          }}
          onMouseLeave={() => {
            setHoverCol(null);
          }}
        >
          {d.totalGrowth ? d.totalGrowth : "Error"}
        </Table.Cell>
        <Table.Cell
          onMouseEnter={() => {
            setHoverCol('hover-col-11');
          }}
          onMouseLeave={() => {
            setHoverCol(null);
          }}
        >
          <span className={`rating ${dividendSafety ? dividendSafety : ""}`}>
            {d.dividendSafety ? dividendSafety : "Error"}
          </span>
        </Table.Cell>
        <Table.Cell className={showDetails ? "up" : "down"}>
          <Button
            icon={`arrow ${showDetails ? "up" : "down"}`}
            onClick={() => {
              setShowDetails(!showDetails);
            }}
          />
        </Table.Cell>
      </Table.Row>
      {showDetails && <ExpandedDetails />}
    </React.Fragment>
  );
};

export default TableRow;
