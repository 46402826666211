import React, { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";
import TableRow from "../components/tableRow";
import _ from "lodash";

const RecordsTable = (props) => {
  const { subsetData } = props;
  console.log(subsetData)

  // Table Sorting
  const [column, setColumn] = useState(null);
  const [direction, setDirection] = useState(null);
  const [sortedData, setSortedData] = useState(subsetData);
  // Row State
  const [hoverCol, setHoverCol] = useState(null)

  useEffect(() => {
    if (subsetData) {
      setSortedData(subsetData);
    }
  }, [subsetData]);

  const handleSort = (clickedColumn) => {
    if (column !== clickedColumn) {
      setColumn(clickedColumn);

      if (sortedData) {
        setSortedData(_.sortBy(sortedData), [clickedColumn]);
      }

      setDirection("ascending");
    } else {
      setSortedData(sortedData.reverse());
      setDirection(direction === "ascending" ? "descending" : "ascending");
    }
  };

  return (
    <Table sortable celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={column === "watch" ? direction : null}
            onClick={() => {
              handleSort("watch");
            }}
          >
            Watch
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "symbol" ? direction : null}
            onClick={() => {
              handleSort("symbol");
            }}
          >
            Symbol
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "priceOpen" ? direction : null}
            onClick={() => {
              handleSort("priceOpen");
            }}
          >
            Price Open
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "divFwd" ? direction : null}
            onClick={() => {
              handleSort("divFwd");
            }}
          >
            Div. Fwd
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "yieldFwd" ? direction : null}
            onClick={() => {
              handleSort("yieldFwd");
            }}
          >
            Yield Fwd
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "payoutRatio" ? direction : null}
            onClick={() => {
              handleSort("payoutRatio");
            }}
          >
            Payout Ratio
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "mktCap" ? direction : null}
            onClick={() => {
              handleSort("mktCap");
            }}
          >
            Mkt. Cap
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "sector" ? direction : null}
            onClick={() => {
              handleSort("sector");
            }}
          >
            Sector
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "priceGrowth" ? direction : null}
            onClick={() => {
              handleSort("priceGrowth");
            }}
          >
            Price Growth
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "dividendGrowth" ? direction : null}
            onClick={() => {
              handleSort("dividendGrowth");
            }}
          >
            Dividend Growth
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "totalGrowth" ? direction : null}
            onClick={() => {
              handleSort("totalGrowth");
            }}
          >
            Total Growth
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={column === "dividendSafety" ? direction : null}
            onClick={() => {
              handleSort("dividendSafety");
            }}
          >
            Dividend Safety
          </Table.HeaderCell>
          <Table.HeaderCell>More</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body className={hoverCol ? hoverCol : ""}>
        {sortedData && sortedData.map((d, index) => <TableRow d={d} setHoverCol={setHoverCol} />)}
      </Table.Body>
    </Table>
  );
};

export default RecordsTable;
