import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Button, Menu, Table, Header } from "semantic-ui-react";
// import { setSearchOptions, setFullData, setShowSearch } from "./dataSlice";
import { setErrorMessage, clearErrorMessage, showLoader } from "../errorSlice";
import SearchModal from "./search";
import RecordsTable from "./recordsTable";
// import Pagination from "./pagination";
import _ from "lodash";

const PaginatedTable = () => {
  const [mainTable, setMainTable] = useState(true);
  const [subsetData, setSubsetData] = useState();
  // Pagination
  const [totalPageNum, setTotalPageNum] = useState();
  const [paginatedIndex, setPaginatedIndex] = useState(0);

  const fullData = useSelector((state) => state.data.stocks);
  const showSearch = useSelector((state) => state.data.showSearch);
  const dispatch = useDispatch();

  // Pagination Way
  // const setSubsetArray = (data, index) => {
  //   const end = (index + 1) * 20;
  //   const start = end >= 20 ? end - 20 : 0;
  //   const subset = JSON.parse(JSON.stringify(data)).slice(start, end);

  //   console.log(start)
  //   console.log(end)
  //   console.log(subset)

  //   setSubsetData(subset);
  //   dispatch(showLoader(false));
  //   window.scrollTo(0, 0);
  // };

  // Infitite Scroll Way
  const setSubsetArray = (data, index) => {
    const end = (index + 1) * 20;
    const start = end >= 20 ? end - 20 : 0;
    const subset = JSON.parse(JSON.stringify(data)).slice(start, end);

    // console.log(start);
    // console.log(end);
    // console.log({ combined: [subsetData, subset] });

    subsetData && subset
      ? setSubsetData([...subsetData, ...subset])
      : setSubsetData(subset);
    dispatch(showLoader(false));
  };

  let tableWrap = useRef(null);
  const scrollPagination = () => {
    if (
      window.pageYOffset + window.innerHeight >
      tableWrap.current.getBoundingClientRect().height
    ) {
      dispatch(showLoader(true));
      setPaginatedIndex(paginatedIndex + 1);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollPagination);

    return () => {
      window.removeEventListener("scroll", scrollPagination);
    };
  }, []);

  useEffect(() => {
    if (fullData && fullData.length > 0) {
      setSubsetArray(fullData, 0);
      // Set Pagination Max Number
      const num = fullData.length / 10;
      Number.isInteger(num)
        ? setTotalPageNum(num)
        : setTotalPageNum(Math.floor(num) + 1);
    }
  }, [fullData]);

  useEffect(() => {
    if (paginatedIndex !== undefined) {
      setSubsetArray(fullData, paginatedIndex);
    }
  }, [paginatedIndex]);

  return (
    <React.Fragment>
      {mainTable && (
        <div id="table-wrap" ref={tableWrap}>
          <RecordsTable subsetData={subsetData} />
          {/* <Pagination
            totalPageNum={totalPageNum}
            paginatedIndex={paginatedIndex}
            setPaginatedIndex={setPaginatedIndex}
          /> */}
        </div>
      )}

      {showSearch && <SearchModal />}
    </React.Fragment>
  );
};

export default PaginatedTable;
