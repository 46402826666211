import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const blankSchema = {}

const initialState = {
  stocks: null
};

const mySlice = createSlice({
  name: "data",
  initialState: initialState,
  reducers: {
    setStocks(state, action){
      state.stocks = action.payload
      return state
    }
  },
});

export const {
  setStocks
} = mySlice.actions;
export default mySlice.reducer;
