import React from 'react'
import { useDispatch } from 'react-redux'
import PaginatedTable from './paginatedTable'
import { generateFakeData } from '../utils/index'
import { setStocks } from '../dataSlice'

const TableView = () => {
    const dispatch = useDispatch()
    const fakeData = generateFakeData(300)
    
    dispatch(setStocks(fakeData))

    return (
        <PaginatedTable  fakeData={fakeData} />
    )
}

export default TableView