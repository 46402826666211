const makeSymbol = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;

  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  
  return result;
};

export const generateFakeData = (count) => {
  const fakeData = [];

  for (let index = 0; index < count; index++) {
    const datum = {
      watch: Math.random > 0.8 ? true : false,
      symbol: makeSymbol(4),
      priceOpen: `$${Math.floor(100 + Math.random() * 900)}`,
      divFwd: `$${Math.random()}`,
      yieldFwd: `$${Math.random()}`,
      payoutRatio: `$${Math.random()}`,
      mktCap: `${Math.floor(100 + Math.random() * 900)}B`,
      sector: makeSymbol(6) + " " + makeSymbol(6),
      priceGrowth: `${Math.floor(10 + Math.random() * 90)}`,
      dividendGrowth: `${Math.floor(10 + Math.random() * 90)}`,
      totalGrowth: `${Math.floor(10 + Math.random() * 90)}`,
      dividendSafety: `${Math.floor(10 + Math.random() * 90)}`,
    };
    fakeData.push(datum);
  }

  return fakeData;
};
