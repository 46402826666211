import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Label, Form, Dropdown } from "semantic-ui-react";

const FilterModal = (props) => {
  const { open, setModalOpen, setApplyFilters } = props;
  const dispatch = useDispatch();
  const [marketCap, setMarketCap] = useState(null);
  const [sector, setSector] = useState(null);
  const [dividendYield, setDividendYield] = useState(null);
  const [dividendSafety, setDividendSafety] = useState(null);

  const marketCapOptions = [
    { key: 1, text: "Mega (Over $200B)", value: "Mega (Over $200B)" },
    { key: 2, text: "Large ($10B to $200B)", value: "Large ($10B to $200B)" },
    { key: 3, text: "Medium ($2B to $20B)", value: "Medium ($2B to $20B)" },
    { key: 4, text: "Small ($300M to $2B)", value: "Small ($300M to $2B)" },
    { key: 5, text: "Micro (Under $300M)", value: "Micro (Under $300M)" },
  ];

  const sectorOptions = [
    { key: 1, text: "Consumer Discretionary", value: "Consumer Discretionary" },
    { key: 2, text: "Consumer Staples", value: "Consumer Staples" },
    { key: 3, text: "Energy", value: "Energy" },
    { key: 4, text: "Financials", value: "Financials" },
    { key: 5, text: "Health Care", value: "Health Care" },
    { key: 6, text: "Industrials", value: "Industrials" },
    { key: 7, text: "Information Technology", value: "Information Technology" },
    { key: 8, text: "Materials", value: "Materials" },
    { key: 9, text: "Real Estate", value: "Real Estate" },
    {
      key: 10,
      text: "Telecommunication Services",
      value: "Telecommunication Services",
    },
    { key: 11, text: "Utilities", value: "Utilities" },
  ];

  const dividendYieldOptions = [
    { key: 1, text: "Need Values", value: "Need Values" },
    { key: 2, text: "Need Values", value: "Need Values" },
    { key: 3, text: "Need Values", value: "Need Values" },
    { key: 4, text: "Need Values", value: "Need Values" },
    { key: 5, text: "Need Values", value: "Need Values" },
  ];

  const dividendSafetyOptions = [
    { key: 1, text: "A", value: "A" },
    { key: 2, text: "B", value: "B" },
    { key: 3, text: "C", value: "C" },
    { key: 4, text: "D", value: "D" },
    { key: 5, text: "F", value: "F" },
  ];

  const resetFilter = () => {
    setMarketCap(null);
    setSector(null);
    setDividendYield(null);
    setDividendSafety(null);
  };

  return (
    <div>
      <Modal
        dimmer="blurring"
        open={open}
        onClose={() => {
          setModalOpen(false);
        }}
        size="tiny"
      >
        <Modal.Header>Filter</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Label>Market Cap</Label>
              <Dropdown
                onChange={(e, { value }) => {
                  setMarketCap(value);
                }}
                options={marketCapOptions}
                placeholder={"Any"}
                selection
                multiple
                value={marketCap}
              />
            </Form.Field>
            <Form.Field>
              <Label>Sector</Label>
              <Dropdown
                onChange={(e, { value }) => {
                  setSector(value);
                }}
                options={sectorOptions}
                placeholder={"Any"}
                selection
                multiple
                value={sector}
              />
            </Form.Field>
            <Form.Field>
              <Label>Dividend Yield</Label>
              <Dropdown
                onChange={(e, { value }) => {
                  setDividendYield(value);
                }}
                options={dividendYieldOptions}
                placeholder={"Any"}
                selection
                multiple
                value={dividendYield}
              />
            </Form.Field>
            <Form.Field>
              <Label>Dividend Safety Score</Label>
              <Dropdown
                onChange={(e, { value }) => {
                  setDividendSafety(value);
                }}
                options={dividendSafetyOptions}
                placeholder={"Any"}
                selection
                multiple
                value={dividendSafety}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={() => {
              setApplyFilters(true);
              setModalOpen(false);
            }}
            id="apply-button"
          >
            Apply
          </Button>
          <Button
            onClick={() => {
              resetFilter();
            }}
            id="reset-button"
          >
            Reset
          </Button>
          <Button
            onClick={() => {
              setModalOpen(false);
            }}
            id="close-button"
          >
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

export default FilterModal;
